import { graphql,Link } from 'gatsby';
import React from 'react';

//import PlanHeader from "../components/plan-header";
import Footer from '../components/footer';
import Menu from '../components/menu';
import SEO from '../components/seo';
import * as headerStyles from '../styles/common.module.css';

const PrivacyPolicyPage = ( { data } ) => {
	return (

		<>
			<SEO keywords="Bubble Insurance Privacy Policy"
				title="Bubble Privacy Policy"
				description="Know more about privacy policy of Get My Bubble. Carefully read our privacy policy &amp; If you have any concerns or questions about any aspect of this Privacy Policy, please contact our Privacy Policy Coordinator at legal@getmybubble.com"/>

			<div className="wrapper">
				<Menu />

				<div>
					<div className="company-info-container about-container" style={{ marginBottom: '48px' }}>
						<h1 className="carrier-plan-heading about-heading" >
            Bubble Privacy Policy
						</h1>
					</div>

					<div className="container privacy-policy">
						<div className="row">
							<div className="col-lg-12">
								<h4><b>Privacy Policy</b></h4>

								<p>Last Updated: November 04, 2024</p>

								<p>Bubble Insurance Solutions, a wholly owned subsidiary of Bubble Technologies, Inc. ("Bubble" or "we") respects your right to privacy. Protecting your privacy is very important to us. We take our obligation to safeguard and secure personal information very seriously. We want you to understand how we protect your privacy and when and how we collect, use, and share information, and how you can exercise your privacy rights.</p>

								<h5><b>INFORMATION WE COLLECT AND WHY WE COLLECT IT</b></h5>

								<p>In general, we collect personal information from or about you for the purposes described in this Privacy Policy or for purposes that we explain to you when or before we collect your personal information. We also may use your personal information for other purposes that are not incompatible with the purposes that we disclose to you but only when permitted by applicable laws. Non-public personally identifiable information is information that identifies you and is not available to the general public.</p>

								<ol className="list-privacy">
									<li>
										<p className="pl-2 mb-4" style={{ display: 'inline' }}><u>We collect the information that you provide to us.</u></p>

										<p style={{ marginTop: '10px' }}>When you use our Site or Services, we may ask you to provide certain personal information and other information.</p><br></br>

										<div className="table-responsive">

											<table className="policy-table table-bordered table-responsive">
												<thead>
													<th className="px-3">
                          Personal Information that Bubble Collects from You
													</th>

													<th>Why Bubble Collects the Personal Information</th>
												</thead>

												<tbody>
													<tr>
														<td className="px-3">Business Contact details, including name, telephone numbers, email address and postal address. </td>

														<td className="px-3">
															<ul>
																<li>To respond to your correspondence and requests and otherwise communicate with you, such as sending you information about our Services that we think will interest you</li>

																<li>For customer service and support for our Services</li>

																<li>To obtain your feedback regarding our Site or Services</li>

																<li>To improve our Services and develop new features</li>

																<li>To monitor and enforce compliance with our Terms of Service or other agreement with you or your employer</li>

																<li>To provide data pursuant to our Bubble LifeScore<sup>TM</sup> application and similar applications on the Site</li>

																<li> To send you information about our Services that we think will interest you</li>

																<li>We collect your email address (legal basis: consent) to send you newsletters and marketing communications</li>
															</ul>
														</td>
													</tr>

													<tr>
														<td className="px-3">User credentials, including name, email address, job title, office address, and other personal information that you or your employer choose to provide to us.</td>

														<td className="px-3">
															<ul>
																<li>To respond to your correspondence and requests and otherwise communicate with you</li>

																<li>To monitor and enforce compliance with our Terms of Service or other agreement with you or your employer</li>

																<li>For customer service and support for our Services</li>

																<li>To improve our Services and develop new features</li>

																<li>To provide life expectancy and similar data</li>

																<li>To provide data pursuant to our Bubble LifeScore<sup>TM</sup> application and similar applications on the Site</li>

																<li>Detect and prevent security incidents</li>

																<li>We collect your payment information (legal basis: contract) to process your transactions.</li>
															</ul>
														</td>
													</tr>

													<tr>
														<td className="px-3">Information you give us when you fill out an on-line information request form, apply for a job or send us an email.</td>

														<td className="px-3">
															<ul>
																<li>To respond to your request in online form or email
																</li>

																<li>To learn more about your products or services if you are a prospective or current vendor or business partner</li>

																<li>To process your application if you apply for a job with us</li>
															</ul>
														</td>
													</tr>

													<tr>
														<td className="px-3">We may collect sensitive data, such as health or biometric information, with your explicit consent. </td>

														<td className="px-3">
                              This data will only be processed when absolutely necessary and in strict compliance with applicable data protection laws.
														</td>
													</tr>
												</tbody>
											</table>
										</div>

										<p></p>

									</li>

									<li>
										<p className="pl-2 mb-4" style={{ display: 'inline' }}>Users of the Services also may upload personal information when requesting a quote or sharing materials.  Bubble does not review this uploaded information to determine whether it contains personal information and processes the uploaded information only as necessary to provide the Services for users of the Services, as described in the Bubble Terms and Conditions.  If you have questions about whether one of Bubble's customers has uploaded your personal information to the Services, please contact that customer (which is the data controller/business that is responsible for that personal information).</p>
									</li>

									<li>
										<p className="pl-2 mb-4" style={{ display: 'inline' }}><u>We automatically collect information when you use the Site or Services.</u></p><br></br>

										<p style={{ marginTop: '10px' }}>We automatically collect certain information from your computer or mobile device when you use the Site or Services.  Some of this automatically collected information is personal information under certain laws.</p>
                  The information that we automatically collect includes:

										<ul style={{ listStyleType: 'disc' }}>
											<li className="normal-text">Information about how your computer or device interacts with our Site and Services, including the date, time, search requests and results, the pages accessed and links clicked</li>

											<li>IP address and broad geographic location (e.g., country or city-level location which Bubble derives from other data) based on IP address, device type, device identification number, browser type, operating system and other technical information. We collect your payment information (legal basis: contract) to process your transactions.</li>
										</ul>

									</li>

									<li>
										<p className="pl-2 mb-4" style={{ display: 'inline' }}> We use this automatically-collected information:</p>

										<ul style={{ listStyleType: 'disc' }}>
											<li className="normal-text">To provide the Site and Services</li>

											<li>To better understand how the Services are used so that we can update and improve the quality of the Services and develop new ones</li>

											<li>For our analytics purposes, such as to track usage of our Site, to measure the number of unique visitors to the different sections of our Site, to understand how people find our Site and to help us make our Site and marketing more useful</li>

											<li>To address suspected or detected unauthorized and illegal activity on or through the Services, such as scraping or fraud, prevent recurrence and design countermeasures</li>

											<li>Detect security incidents</li>

											<li>Identify and repair bugs in the Services</li>
										</ul>
									</li>

									<li>
										<p className="pl-2 mb-4" style={{ display: 'inline' }} > Some of this information is automatically collected using cookies and similar tracking technology. See our NOTICE ABOUT COOKIES AND TRACKING TECHNOLOGY below.</p>
									</li>

									<li>
										<p className="pl-2 mb-4" style={{ display: 'inline' }}><u>We collect information from third party sources</u></p><br></br>

										<p style={{ marginTop: '10px' }}>From time to time, we may receive personal information about you from third-party sources, which include:</p>

										<ul style={{ listStyleType: 'disc' }}>
											<li className="normal-text">Marketing partners that help us identify individuals who may be interested in
                      learning more about the Services and to supplement personal information we
                      already have about you, such as when we acquire an email list </li>

											<li>Resellers and other business partners that may share personal information with us
                      when we offer "white label" access to the Services.</li>
										</ul>

										<p>We only accept personal information from third parties when we have confirmed that those third parties have the authorization to share it or are otherwise legally permitted or are required to disclose your personal information to us.</p>
									</li>

									<li>
										<p className="pl-2 mb-4" style={{ display: 'inline' }}><u>Your Privacy Rights</u></p><br></br>

										<p style={{ marginTop: '10px' }}>Bubble respects your privacy rights and provides you with the following rights regarding your personal information:</p>

										<ul style={{ listStyleType: 'disc' }}>
											<li className="normal-text"><b>Right of Access: </b> You have the right to request access to your personal information that we hold.</li>

											<li><b>Right to Rectification: </b> You have the right to request that we correct any inaccuracies in your personal information</li>

											<li><b>Right to Erasure: </b> You have the right to request the deletion of your personal information, subject to certain exceptions.</li>

											<li><b>Right to Restriction of Processing: </b> You have the right to request that we restrict the processing of your personal information in certain circumstances.</li>

											<li><b>Right to Data Portability: </b> You have the right to receive your personal information in a structured, commonly used, and machine-readable format and to transmit that information to another controller.</li>

											<li><b>Right to Object:  </b> You have the right to object to the processing of your personal information based on legitimate interests or direct marketing.</li>

										</ul>

										<p>To exercise any of these rights, please contact us using the information provided in the 'How to Contact Us' section below</p>

										<li>
											<p className="pl-2 mb-4" style={{ display: 'inline' }}>We may use automated decision-making or profiling to tailor our services, detect fraud, or personalize marketing efforts. You have the right to object to such processing and request human intervention in significant decisions affecting you.</p>
										</li>
									</li>
								</ol>

								<h5><b>INFORMATION ABOUT YOUR TRANSACTIONS</b></h5>

								<p>We may collect Information about your transactions and experiences with others, and us such as your payment history, claims, coverage, and policy changes.</p>

								<h5><b> HOW BUBBLE SHARES PERSONAL INFORMATION</b></h5>

								<p>We may disclose your personal information to the following categories of recipients:</p>

								<ul className="list-privacy">
									<li className="normal-text">Our vendors, such as data hosting providers and payment card processors, that help us provide the Services and Site for you;</li>

									<li>Marketing partners that help us identify individuals who may be interested in learning more about the Services and to supplement personal information we already have about you, such as when we acquire an email list </li>

									<li>Competent law enforcement, government agencies, courts, regulators or other third parties when we believe disclosure is necessary
                  (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect your vital interests or those of any other person;</li>

									<li>Potential acquirers and their agents and professional advisers in connection with any proposed purchase, merger or acquisition of any part of our business. We will inform the buyer that the terms of this Privacy Policy apply to your personal information;</li>

									<li>With resellers and other business partners with which we share personal information in order to offer “white label” access to the Service; and</li>

									<li>Any other person with your consent to the disclosure.</li>
								</ul>

								<h5><b>DATA SECURITY</b></h5>

								<p style={{ marginTop: '10px' }}>Bubble takes the security of your personal information seriously. We implement appropriate technical and organizational measures to protect your information from unauthorized access, use, disclosure, alteration, or destruction. These measures include: </p>

								<ul className="list-privacy" style={{ listStyleType: 'disc' }}>
									<li className="normal-text">Encryption of sensitive data</li>

									<li>Access controls and authentication mechanisms</li>

									<li>Regular security assessments and vulnerability scanning</li>

									<li>Employee training on data security and privacy practices</li>

								</ul>

								<h5><b>DATA RETENTION</b></h5>

								<p style={{ marginTop: '10px' }}>We retain your personal information for as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations. Specific retention periods vary depending on the type of information and the purpose of processing. For example, we retain customer account information for ten (10) years after the termination of the account. </p>


								<h5><b> NOTICE ABOUT OUR USE OF COOKIES AND SIMILAR TRACKING TECHNOLOGY</b></h5>

								<p>We use cookies and other data tracking technology to collect and use personal information about you. We also may allow others to use cookies and similar technologies (e.g., pixels) to recognize you and/or your device(s) when you use the Site or Services.</p>

								<p><b>What are cookies?</b></p>

								<p>Cookies are small text files that are sent to or accessed from your web browser or your
                computer’s hard drive. A cookie typically contains the Name of the domain (internet location)
                from which the cookie originated, the “lifetime” of the cookie (i.e., when it expires) and a
                randomly generated unique number or similar identifier. A cookie also may contain information
                about your computer, such as user settings, browsing history and activities conducted while
                using the Site and certain Services.</p>

								<p>Bubble also uses “pixels” (also known as web beacons). Pixels are transparent images that are used in collecting information about website usage across websites and over time.</p>

								<p>Cookies that Bubble sets on the Site or through the Services are called first-party cookies. Cookies set by any
                other party are called third-party cookies. Third-party cookies enable third-party features or functionality
                on or through the Site or through the Services, such as analytics, marketing automation and customer support.
                The third parties that set third-party cookies (such as Google) use cookies to recognize your browser when you
                use your computer or device to visit the Site and also when to visit certain other websites before or after
                you visit the Site. </p>

								<p>
                Most browsers accept cookies automatically but allow you to disable them by selecting the appropriate settings on your browser as discussed in this notice.  You also can opt-out of certain cookies using the following links: <a href="https://optout.networkadvertising.org/?c=1" className="privacy-link" target="_blank" rel="noopener noreferrer">https://optout.networkadvertising.org</a>,&nbsp;

									<a href="https://optout.aboutads.info/?c=2&lang=EN" className="privacy-link" target="_blank" rel="noopener noreferrer">https://optout.aboutads.info</a> (US) or

									<a href="https://www.youronlinechoices.com/uk/your-ad-choices" className="privacy-link"> https://www.youronlinechoices.com/uk/your-ad-choices</a> (EU).  You can reset your browser to refuse all cookies or to indicate when a cookie is being sent. Some features of our Site and Services may not work properly without cookies.

                To learn more about cookies generally, visit <a href="http://www.allaboutcookies.org/" className="privacy-link">www.allaboutcookies.org.</a>
								</p>

								<p>
                Some web browsers (including Brave, Safari, Internet Explorer, Firefox and Chrome) incorporate a “Do Not Track” (“DNT”) or similar feature that signals to websites that a user does not want to have his or her online activity and behavior tracked. If a website that responds to a particular DNT signal receives the DNT signal, the browser can block that website from collecting certain information about the browser’s user. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason, many website operators, including Bubble, do not respond to DNT signals.
								</p>

								<p><b> Why does Bubble use cookies and other tracking technology?</b></p>

								<p>Some cookies are required for the Site or Services to operate.Other cookies enable us to track the interests of Site users, to enhance the experience the Site and to target advertising.</p>

								<p>The types of cookies served the Site and why they are used is as follows:</p>

								<ul className="list-privacy">
									<li className="normal-text">"Strictly necessary" cookies are required to allow us to deliver the Sites to you and to provide specific services that you request from us.</li>

									<li>“Performance” or “Analytics” cookies help us to collect information about how visitors use the Site and helps us analyze and improve the Site. Performance or analytics cookies remain on your computer after you close your
                  browser until you delete them.</li>

									<li>“Advertising” cookies are used to make advertising messages more relevant to you. They help to display advertisements that are based on your inferred interests, prevent the same ad from appearing too often and ensuring that ads are properly displayed for advertisers.</li>
								</ul>

								<p>Bubble currently does not serve targeted advertisements on the Site. Bubble may use information about your visits to the Site to provide relevant advertisements on other websites.  Bubble and third parties also may deploy technology that measures the effectiveness of the advertisements on other websites by using cookies or web beacons to collect information about your visits to the Site and other websites to provide advertisements on other websites about goods and services that may interest you. The information collected through this process does not enable us or the third parties to identify your name, contact details or similar directly-identifying information unless you choose to provide them.</p>

								<p>The cookies used on the Site and Services are:</p>

								<table className="policy-table table-bordered custom_wdith_privacy_policy">
									<thead>
										<th className="px-3">Third Party</th>

										<th>Cookie Type</th>
									</thead>

									<tbody>
										<tr>
											<td className="px-3">Amplitude</td>

											<td>Analytics Cookie</td>
										</tr>

										<tr>
											<td className="px-3">Google Tag Manager</td>

											<td>Essential Cookie</td>
										</tr>

										<tr>
											<td className="px-3">Chargebee</td>

											<td>Persistent</td>
										</tr>

										<tr>
											<td className="px-3">DoubleClick</td>

											<td>Advertising Cookie</td>
										</tr>
									</tbody>
								</table>

								<p>We also use Google Analytics and its affiliate Double Click.   Google Analytics provides us with demographic data about Site users, such as age and gender, to help us analyze how visitors use the Site and Services. DoubleClick uses advertising cookies that DoubleClick recognizes when you visit other websites so that ads are served to you based on your inferred interests from the websites you visit. The information generated by the cookie about your use of the Site and Services (including your IP address) is transmitted to and stored by Google on servers in the United States. On behalf of Bubble, Google will use this information to evaluate your use of the Site and compile reports on your activity for us and third parties who help operate and provide services related to Bubble. Google will not associate your IP address with any other data held by Google.</p>

								<p>
                You may opt out of Google’s personalized advertising by visiting Ads Settings at <a href="https://adssettings.google.com/authenticated" className="privacy-link">https://adssettings.google.com/authenticated.</a> Google Analytics has developed an opt-out browser add-on; if you want to opt out of Google Analytics, you can &nbsp;

									<a href="https://tools.google.com/dlpage/gaoptout" className="privacy-link">download and install the add-on</a> &nbsp;

                 for your web browser.  For more information about how Google collects, uses, and shares your information, please visit the Google Privacy Policies - at &nbsp;
									<a href="https://www.google.com/policies/privacy/partners/" className="privacy-link">https://www.google.com/policies/privacy/partners/ </a> or &nbsp;

									<a href="https://policies.google.com/privacy?hl=en-US" className="privacy-link">Google’s Privacy Policy.</a>
								</p>

								<p>Pixels and similar data collection technology enables Bubble to monitor the traffic patterns of visitors from one Site page to another, to deliver or communicate with cookies, to understand whether you visit the Site after seeing our online advertisement displayed on a third-party website, to improve performance of the Site and to measure the success of our email marketing campaigns.</p>

								<br></br>

								<h5><b>HOW BUBBLE PROTECTS PERSONAL INFORMATION</b></h5>

								<p>Bubble uses technical, physical and administrative safeguards designed to protect the personal information that we collect from and about you.  Our safeguards are designed to provide a level of security appropriate to the risk of processing your personal information and include (as applicable) measures to ensure the ongoing confidentiality, integrity, availability and resilience of our processing systems and a procedure for regularly testing, assessing and evaluating the effectiveness of our security measures for ensuring the security of the processing of personal information.</p>

								<p>You are responsible for maintaining the security of your account credentials for the Services. Bubble will treat access to the Services through your account credentials as authorized by you.  We may suspend your use of all or part of the Services without notice if we suspect or detect any breach of security.  If you believe that information you provided to us is no longer secure, please notify us immediately using the contact information provided below.</p>

								<p>In the event of a data breach that compromises your personal information, we will notify affected individuals and relevant authorities within the legally required timeframe. This notification will include details about the breach, its impact, and any steps taken to mitigate further risks.</p>

								<p>If we become aware of a breach that affects the security of your personal information, we will provide you or your employer with notice as required by applicable law.  To the extent permitted by applicable law, Bubble will provide its notice to you through the email address associated with your account.</p>

								<p><b>UNAUTHORIZED ACCESS TO THE SITE OR SERVICES – INCLUDING SCRAPING – IS PROHIBITED AND MAY LEAD TO CRIMINAL PROSECUTION.</b></p>

								<h5><b>HOW BUBBLE RETAINS PERSONAL INFORMATION</b></h5>

								<p>We retain personal information for as long we have a legitimate business need to do so, such for the duration of your subscription or to comply with applicable legal, taxation or accounting requirements.
								</p>

								<p>When we have no longer have a lawful basis for processing your personal information, we will either delete or anonymize your personal information.  If we cannot delete or anonymize your personal information, then we will segregate and securely store your personal information until deletion or anonymization is possible.  Once your personal information is anonymized, it is no longer personal information subject to this Privacy Policy.</p>

								<h5><b>CHILDREN’S PRIVACY</b></h5>

								<p>The Site and Services are not intended for use by minors (i.e., children under the age of majority in a particular jurisdiction) or to collect personal information from minors.</p>

								<p>Bubble does not knowingly collect personal information from minors. Consistent with the requirements of applicable law, if we learn that a minor has improperly provided us with personal information, we will use that information only to respond directly to that child (or his or her parent or legal guardian) to inform the minor that he or she cannot use the Sire or Services and subsequently will delete that information in compliance with COPPA and GDPR regulations.</p>

								<h5><b>YOUR CHOICES ABOUT YOUR PERSONAL INFORMATION</b></h5>

								<p>IF YOU ARE LOCATED IN A JURISDICTION WITH DATA PROTECTION LAWS THAT OFFER YOU PRIVACY RIGHTS NOT DESCRIBED IN THIS PRIVACY POLICY, PLEASE CONTACT US AT (833) 900-2822 or by email at <a href="mailto:legal@getmybubble.com" target="_self" className="privacy-link">legal@getmybubble.com</a>. We respect your privacy rights and will do our best to accommodate your requests.</p>

								<p><b>California Privacy Notice</b></p>

								<p>This California Privacy Notice applies to the processing of Personal Information of residents of the State of California (“<b>California Residents</b>”) as required by the California Resident Privacy Act of 2018 (“

									<b>CCPA</b>”).</p>

								<p>If you are a California Resident, this California Privacy Notice explains your privacy rights and is intended to inform you at or before you provide us with your Personal Information how and why we process your Personal Information.  In this California Privacy Notice, “Personal Information” means information that identifies, relates to, describes, is capable of being associated with or could reasonably be linked, directly or indirectly, with a particular California Resident or household.</p>

								<p> If this California Privacy Notice and any provision in the rest of our Privacy Policy conflict, then this California Privacy Notice controls the processing of Personal Information of California Residents.</p>

								<p>We may not honor part or all of a request you make to exercise your rights under CCPA – for example, certain information we collect is exempt from this California Privacy Notice, such as publicly-available information collected from a government agency.  Most personal information collected by Bubble within the context of providing our Services and related services to a California Resident who is an employee or representative of a business is exempt from CCPA until January 1, 2021.</p>

								<p><u><b>California Privacy Rights</b></u></p>

								<p>California Residents have the following rights:</p>

								<ul className="list-privacy">
									<li className="normal-text">You have the right to request information about the categories and specific pieces of Personal Information that Bubble has collected about you (see table below), as well as the categories of sources from which such information is collected, the purpose for collecting such information and the categories of third parties with whom we share such information.</li>

									<li>You have the right to opt out of Bubble’s sale of your Personal Information if and when Bubble determines the purpose and means of processing your Personal Information. (In CCPA, “sale” means transferring or making available Personal Information to third parties for monetary or other valuable consideration.)  BUBBLE DOES NOT SELL PERSONAL INFORMATION.  Accordingly, Bubble does not offer a means by which to opt-out of sale of personal information.</li>

									<li>You have the right to request information about our disclosure for business purposes of your Personal Information to third parties.</li>

									<li>You have the right to request the deletion of your Personal Information.</li>

									<li>You have the right to not be discriminated against for exercising any of these rights.</li>
								</ul>

								<p>The table below lists categories of Personal Information that generally match the categories in the definition of Personal Information in CCPA.  We indicate for each listed category the specific types of Personal Information in the category that Bubble collected within the last 12 months.</p>

								<div className="table-responsive">
									<table className="policy-table table-bordered table-responsive">
										<thead style={{ backgroundColor: '#2f5496', color: 'white' }}>
											<th className="px-3">Category of Personal Information in CCPA</th>

											<th>Examples of Personal Information in CCPA Category that Bubble Collects</th>

											<th>Why Bubble Collects this Personal Information</th>
										</thead>

										<tbody>
											<tr>
												<td className="px-3" valign="top">
                        Identifiers
												</td>

												<td>Name, postal address, email address, unique identifier, Internet Protocol
                        address, user account name, Social Security number and similar identifiers.</td>

												<td>
													<ul className="list-privacy" style={{ listStyle: 'circle' }}>
														<li>To troubleshoot problems</li>

														<li>To learn how users interact with our Services</li>

														<li>To improve our Services and develop new features</li>

														<li>To customize your experience</li>

														<li>To promote our Services</li>

														<li>To provide data pursuant to our Bubble LifeScore<sup>TM</sup> application and similar applications on the Site</li>

														<li>To communicate with you</li>
													</ul>
												</td>
											</tr>

											<tr>
												<td className="px-3">
                        Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))
												</td>

												<td>
                        Name, signature, Social Security number, address, telephone number and current employer and work address

												</td>

												<td>
													<ul className="list-privacy" style={{ listStyle: 'circle' }}>
														<li>To troubleshoot problems</li>

														<li>To learn how users interact with our Services</li>

														<li>To improve our Services and develop new features</li>

														<li>To customize your experience</li>

														<li>To promote our Services</li>

														<li>To provide data pursuant to our Bubble LifeScore<sup>TM</sup> application and similar applications on the Site</li>

														<li>To communicate with you</li>
													</ul>
												</td>
											</tr>

											<tr>
												<td className="px-3">Protected classification characteristics under California or federal law</td>

												<td>None</td>

												<td>N/A</td>
											</tr>

											<tr>
												<td className="px-3">Commercial information</td>

												<td>
                        Records of Bubble products or services purchased, or considered, or other purchasing or consuming histories or tendencies.

												</td>

												<td>
													<ul className="list-privacy" style={{ listStyle: 'circle' }}>
														<li>To customize your experience</li>

														<li>To promote our products and services</li>

														<li>To improve our products and services and develop new features</li>

														<li>To communicate with you</li>
													</ul>
												</td>
											</tr>

											<tr>
												<td className="px-3">Biometric information</td>

												<td>None</td>

												<td>N/A</td>
											</tr>

											<tr>
												<td className="px-3">
                        Internet or other similar network activity
												</td>

												<td>
                        Browsing history, search history, information on a consumer’s interaction with online services and digital advertisements.

												</td>

												<td>
													<ul className="list-privacy" style={{ listStyle: 'circle' }}>

														<li>To learn how users interact with our Services</li>

														<li>To improve our products and services and develop new features</li>

														<li>To customize your experience</li>

														<li>To promote our products and services</li>

														<li>To communicate with you</li>
													</ul>
												</td>
											</tr>

											<tr>
												<td className="px-3">Geolocation data</td>

												<td>IP address</td>

												<td>We collect your IP address automatically.  We may be able to determine your general location based on the IP address. Our mobile applications do <i>not</i> collect your precise location (e.g., your GPS coordinates).</td>
											</tr>

											<tr>
												<td className="px-3">Audio, electronic, visual, thermal, olfactory or similar information</td>

												<td>If you contact us via telephone, we may record the telephone call. We do not collect any thermal, olfactory or similar information.</td>

												<td>If we record a telephone call, we do so with your prior consent, for quality control and process improvement.</td>
											</tr>

											<tr>
												<td className="px-3">Professional or employment-related information</td>

												<td>
                        Current employer and work address,
                        information about your current employer, title, work address and work email so that we can associate you with a particular customer or account.
												</td>

												<td>
													<ul className="list-privacy" style={{ listStyle: 'circle' }}>

														<li>To promote our services</li>

														<li>To communicate with you</li>

														<li>To provide data pursuant to our Bubble LifeScore<sup>TM</sup> application and similar applications on the Site</li>
													</ul>
												</td>
											</tr>

											<tr>
												<td className="px-3">Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. § 1232g, 34 C.F.R. Part 99))</td>

												<td>None</td>

												<td>N/A</td>
											</tr>

											<tr>
												<td className="px-3">Inferences drawn from other categories (A. – L above) to create a profile about a consumer</td>

												<td>Profile reflecting the resident’s preferences and characteristics.</td>

												<td>
													<ul className="list-privacy" style={{ listStyle: 'circle' }}>

														<li>To customize your experience</li>

														<li>To promote our Services</li>

													</ul>
												</td>
											</tr>

										</tbody>
									</table>
								</div>

								<div></div>

								<p>Users of the Services also may upload personal information when requesting a quote or sharing materials through the Services, as described in the Bubble Platform Terms available at <a href="https://www.getmybubble.com/terms-of-service" className="privacy-link">https://www.getmybubble.com/terms-of-service</a>. Bubble does not review this uploaded information to determine whether it contains personal information.  If you have questions about whether one of Bubble’s customers has uploaded any specific category or pieces of personal information to the Services, please contact that customer.</p>

								<p>We provide information about how we use and disclose personal information above in this Privacy Policy.</p>

								<p><u><b>How to Submit Your Request to Exercise Your California Privacy Rights</b></u></p>

								<p>If you are a California resident and would like to exercise one or more of your privacy rights, please:</p>

								<ul className="list-privacy">
									<li className="normal-text">Call us at (833) 900-2822. Please tell our customer service team that you are a California resident and would like to exercise your privacy rights.</li>

									<li>Send an email to <a href="mailto:legal@getmybubble.com" target="_self" className="privacy-link">legal@getmybubble.com</a>. Please include “California Privacy Rights” in the subject line.</li>
								</ul>

								<p> A different California law permits California residents to request a notice disclosing the categories of personal information about you that we have shared with third parties for their direct marketing purposes during the preceding calendar year.  To request this notice, please submit your request by mail to or by email to <a href="mailto:legal@getmybubble.com" className="privacy-link" target="_self">legal@getmybubble.com</a>.  Please include “California Privacy Rights” in the subject line. Please note, however, that Bubble currently does not share personal information with third parties for their direct marketing purposes.</p>

								<h5><b> INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</b></h5>

								<p>Bubble may transfer your personal information to jurisdictions other than the one in which you reside.  These other jurisdictions may have data protection laws that are different from the laws of your place of residence (and, in some cases, not as protective).</p>

								<p>Specifically, Bubble processes information in the cloud through Amazon Web Services on servers located in the U.S. When we collect your personal information, we may transfer it to or process it in other jurisdictions where we operate.  Also, some of the third-party service providers with which we may share personal information are located in and transfer personal information to various jurisdictions around the world.</p>

								<p>Bubble primarily uses the European Union Commission’s Standard Contractual Clauses for transfers of personal information from the European Economic Area, Switzerland and the United Kingdom to other countries outside the European Economic Area, Switzerland and the United Kingdom.</p>

								<h6><b>UPDATES TO THIS PRIVACY POLICY</b></h6>

								<p>We may update this Privacy Policy from time to time in response to changing legal, technical or business developments.  When we update our Privacy Policy, we will post the updated version and change the Effective Date above.  We also will take appropriate measures to inform you in advance so that you have an opportunity to review the revised Privacy Policy before it is effective.  If your consent is required by applicable privacy laws, we will obtain your consent to changes before the revised Privacy Policy applies to you.</p>

								<h6><b> HOW TO CONTACT US</b></h6>

								<p> If you have any concerns or questions about any aspect of this Privacy Policy, please contact our Privacy Policy Coordinator as follows:</p>

								<p>Bubble Technologies, Inc. <br />

                Attn: Legal Dept<br />

                1267 Willis Street, Suite 200 <br />

                Redding, California 96001<br />

									<a href="mailto:legal@getmybubble.com" target="_self" className="privacy-link">legal@getmybubble.com</a></p>
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};

export default PrivacyPolicyPage;
